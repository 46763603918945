import { language } from './index';

import frenchLang from '../assets/images/france.svg';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'french',
      locale: 'fr',
      text: 'French',
      icon: frenchLang,
    },
  ],
};

export function getCurrentLanguage (lang) {
  return config.options.find(language => language.languageId === lang) || config.options[0];
}
export default config;
