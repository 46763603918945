import React, {useState, Fragment, useEffect} from "react";
import {AdaButton, AdaSelect, AdaInputText} from "@adaming/ada-react-component";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";

import * as service from "../../../service/crud.service";
import {useForm} from "react-hook-form";
import Col from "reactstrap/es/Col";
import {useHistory} from "react-router-dom";


export default function EventLine(props) {
    let history = useHistory();
    const context = "event_settings";
    const {register, handleSubmit, errors, setValue, control} = useForm();

    const [eventSetting, setEventSetting] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [selectedEnableEvent, setSelectedEnableEvent] = React.useState(false);
    const [selectedEmployee, setSelectedEmployee] = React.useState(false);
    const [selectedCreator, setSelectedCreator] = React.useState(false);
    const [selectedEmployees, setSelectedEmployees] = React.useState();
    const [selectedTemplate, setSelectedTemplate] = React.useState();
    const [selectedApplicationModule, setSelectedApplicationModule] = React.useState();
    const [resource, setResource] = React.useState();

    useEffect(() => {
            setEventSetting(props.eventSetting);
            initData(props.eventSetting)
        },
        []
    );

    function initData(data) {
        if (data.employees !== undefined) {
            let listSelectedEmployees = [];
            data.employees.forEach(item => {
                    listSelectedEmployees.push({
                        key: item.id,
                        label: item.firstName + " " + item.lastName,
                        value: item.id,
                        object: item
                    })
                }
            );
            setSelectedEmployees(listSelectedEmployees);
        }

        if (data.applicationModule !== undefined) {
            let app = {
                key: data.applicationModule.id,
                label: data.applicationModule.name,
                value: data.applicationModule.id,
                object: data.applicationModule
            };
            setSelectedApplicationModule(app);
        }

        if (data.template !== undefined) {
            let app = {
                key: data.template.id,
                label: data.template.name,
                value: data.template.id,
                object: data.template
            };
            setSelectedTemplate(app);
        }

        setSelectedEnableEvent(data.enableEvent);
        setSelectedEmployee(data.employee);
        setSelectedCreator(data.creator);
        setResource(data.resource);
    }

    function checkenableEvent() {
        return eventSetting.enableEvent === true;
    }

    function handleEnableEvent(statusOption) {
        console.log(statusOption);
        setSelectedEnableEvent(statusOption);
        eventSetting.enableEvent = statusOption;
    };

    function handleEmployee(statusOption) {
        console.log(statusOption);
        setSelectedEmployee(statusOption);
        eventSetting.employee = statusOption;
    };

    function handleCreator(statusOption) {
        console.log(statusOption);
        setSelectedCreator(statusOption);
        eventSetting.creator = statusOption;
    };

    const handleEmployees = selectedOption => {
        eventSetting.employees = [];
        if (selectedOption) {
            selectedOption.forEach(item => {
                eventSetting.employees.push(item.object)
            });
        }
        setSelectedEmployees(selectedOption);
    };

    const handleApplicationModules = selectedOption => {
        eventSetting.applicationModule = selectedOption.object;
        setSelectedApplicationModule(selectedOption);
    };

    const handleTemplate = selectedOption => {
        eventSetting.template = selectedOption.object;
        setSelectedTemplate(selectedOption);
    };

    const handleResource = selectedOption => {
        eventSetting.resource = selectedOption;
        setResource(selectedOption);
    };

    const onSubmit = data => {
        console.log(eventSetting);
        console.log(data);
        eventSetting.resource = data.resource;
        eventSetting.libelle = data.libelle;
        if (eventSetting.id !== undefined) {
            service.put(`${process.env.REACT_APP_API_URL}/mail-service`, context, () => history.push("/" + context), () => {
            }, eventSetting)
        } else {
            service.post(`${process.env.REACT_APP_API_URL}/mail-service`, context, () => history.push("/" + context), () => {
            }, eventSetting)
        }
    };


    return (
        <Fragment key={props.index}>
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
                <div>
                    <div className="row ma-2">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body">
                                    <form onSubmit={handleSubmit(onSubmit)} id={props.index}>

                                        <Row>
                                            <Col className="col-sm-6">
                                                <FormGroup>
                                                    Libellé
                                                    <AdaInputText
                                                        name="libelle"
                                                        defaultValue={eventSetting.libelle}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <FormGroup key={"ssss" + props.index}>
                                                    <CustomInput
                                                        key={"eventEnableOui" + props.index}
                                                        id={"eventEnableOui" + props.index} type="radio"
                                                        name={"enableEvent" + props.index} value="true"
                                                        checked={selectedEnableEvent === true}
                                                        label={"Activé"}
                                                        onChange={() => handleEnableEvent(true)}
                                                    /></FormGroup>
                                            </Col>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <FormGroup key={"qsdq" + props.index}>
                                                    <CustomInput
                                                        key={"eventEnableNon" + props.index}
                                                        id={"eventEnableNon" + props.index} type="radio"
                                                        name={"enableEvent" + props.index} value="false"
                                                        checked={selectedEnableEvent === false}
                                                        label={"Non activé"}
                                                        onChange={() => handleEnableEvent(false)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {eventSetting.enableEvent && <Row>
                                            <Col className="col-sm-6">
                                                <FormGroup key={"appliList" + props.index}>
                                                    Les modules applicatifs
                                                    <AdaSelect
                                                        id={"appliList" + props.index}
                                                        key={"appliList" + props.index}
                                                        name={"appliList" + props.index}
                                                        value={selectedApplicationModule}
                                                        options={props.applicationList}
                                                        onChange={handleApplicationModules}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-sm-6">
                                                <FormGroup>
                                                    Resource
                                                    <AdaInputText
                                                        name="resource"
                                                        defaultValue={resource}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }

                                        {eventSetting.enableEvent && <Row>
                                            <Col className="col-sm-6">
                                                <FormGroup key={"template" + props.index}>
                                                    Le template
                                                    <AdaSelect
                                                        id={"templateList" + props.index}
                                                        key={"templateList" + props.index}
                                                        name={"templateList" + props.index}
                                                        value={selectedTemplate}
                                                        options={props.templateList}
                                                        onChange={handleTemplate}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }

                                        {eventSetting.enableEvent && <Row>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >
                                                Notification salarié
                                            </Col>
                                            <Col
                                                className="col-sm-1"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <CustomInput
                                                    key={"employeeOui" + props.index}
                                                    id={"employeeOui" + props.index} type="radio"
                                                    name={"employee" + props.index} value="true"
                                                    checked={selectedEmployee === true}
                                                    label={"Oui"}
                                                    onChange={() => handleEmployee(true)}
                                                />
                                            </Col>
                                            <Col
                                                className="col-sm-1"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <CustomInput
                                                    key={"employeeNon" + props.index}
                                                    id={"employeeNon" + props.index} type="radio"
                                                    name={"employee" + props.index} value="false"
                                                    checked={selectedEmployee === false}
                                                    label={"Non"}
                                                    onChange={() => handleEmployee(false)}
                                                />
                                            </Col>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >

                                            </Col>

                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >
                                                Notification créateur
                                            </Col>
                                            <Col
                                                className="col-sm-1"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <CustomInput
                                                    key={"creatorOui" + props.index}
                                                    id={"creatorOui" + props.index} type="radio"
                                                    name={"creator" + props.index} value="true"
                                                    checked={selectedCreator === true}
                                                    label={"Activé"}
                                                    onChange={() => handleCreator(true)}
                                                />
                                            </Col>
                                            <Col
                                                className="col-sm-1"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <CustomInput
                                                    key={"creatorNon" + props.index}
                                                    id={"creatorNon" + props.index} type="radio"
                                                    name={"creator" + props.index} value="false"
                                                    checked={selectedCreator === false}
                                                    label={"Non"}
                                                    onChange={() => handleCreator(false)}
                                                />
                                            </Col>
                                        </Row>}
                                        <br/>
                                        {eventSetting.enableEvent && <Row>
                                            <Col className="col-sm-6">
                                                <FormGroup key={"employee" + props.index}>
                                                    Les employés
                                                    <AdaSelect
                                                        id={"employeeList" + props.index}
                                                        key={"employeeList" + props.index}
                                                        name={"employeeList" + props.index}
                                                        isMulti
                                                        value={selectedEmployees}
                                                        options={props.employeeList}
                                                        onChange={handleEmployees}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }
                                        <br/>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <AdaButton
                                                        type="submit">
                                                        Valider
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </Fragment>
    );
}
;
