
import Maintenance from "../views/pages/error/maintenance";
import Mail from "../views/settings/mail/Mail";
import Template from "../views/settings/template/Template";
import Employee from "../views/settings/employee/Employee";
import Event from "../views/settings/event/Event";

const settingRoutes = [
    {path: "/mail", component: Mail},
    {path: "/template", component: Template},
    {path: "/maintenance", component: Maintenance},
    {path:"/employee_setting", component:Employee},
    {path:"/event_settings", component:Event}
];

export default settingRoutes;
