import React, {useState, useEffect} from "react";
import {withRouter} from "react-router";
import { getHostByAppName, MAIL_NAME} from "../../../service/host";

import {get} from "../../../service/crud.service"
import TemplateLine from "./TemplateLine";

const Event = props => {
        const [isLoading, setIsLoading] = useState(true);
        const [templatesList, setTemplatesList] = useState({});


        useEffect(() => {
            get(getHostByAppName(MAIL_NAME), "templates", init, () => {
            })
        }, [])

        const init = (data) => {
            setTemplatesList(data);
            console.log(templatesList);
            setIsLoading(false);
        }

        return (

            <div>
                {isLoading ? (<div></div>) :
                    (
                        <div className="row ma-2">
                            <div className="col-sm-12 ptb-15">
                                <div className="roe-card-style">
                                    <div className="roe-card-body">
                                        {templatesList.map((data, i) => (

                                                <TemplateLine template={data} index={i} key={{i}}
                                                           id={i}/>

                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
;
export default Event;
