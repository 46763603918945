import Frlang from './entries/fr_FR';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  fr: Frlang
};

addLocaleData(AppLocale.fr.data);

export default AppLocale;


