import React, {useState} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";
import {withRouter} from "react-router";
import {getHostByAppName, MAIL_NAME} from "../../../service/host";
import * as service from "../../../service/crud.service";
import {useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import Col from "reactstrap/es/Col";
import {useHistory} from "react-router-dom";


const context = "settings";
const Mail = props => {
    let history = useHistory();

    const [errorMessage, setErrorMessage] = React.useState("");
    const [settings, setSettings] = useState([]);
    /*user detail */

    const {register, handleSubmit, errors, setValue, control} = useForm();

    React.useEffect(() => {
        initList();
    }, []);


    const initList = () => {
        service.get(getHostByAppName(MAIL_NAME), context, callbackRes, errorCatch)
    };

    const errorCatch = error => {
        console.log("error : ", error);
    };

    const callbackRes = data => {
        setSettings(data[0]);
        console.log("liste des settings : ", data);
    };

    const onSubmit = data => {
        service.put(getHostByAppName(MAIL_NAME), context, () => history.push("/"), setErrorMessage, {...settings, ...data})
    };


    return (

        <div>{settings &&
        <div className="row ma-0">
            <div className="col-sm-12 ptb-15">
                <div className="roe-card-style">
                    <div className="roe-card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <Label>
                                        <p style={{color: "red"}}> {errorMessage}</p>
                                    </Label>
                                    <FormGroup>
                                        Host
                                        <AdaInputText
                                            name="mailHost"
                                            defaultValue={settings.mailHost}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        Username
                                        <AdaInputText
                                            name="mailUsername"
                                            defaultValue={settings.mailUsername}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        Password
                                        <AdaInputText
                                            name="mailPassword"
                                            defaultValue={settings.mailPassword}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        Transport protocol
                                        <AdaInputText
                                            name="mailTransportprotocol"
                                            defaultValue={settings.mailTransportprotocol}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        SMTP Auth
                                        <AdaInputText
                                            name="mailStmpAuth"
                                            defaultValue={settings.mailStmpAuth}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        SMTP Port
                                        <AdaInputText
                                            name="mailStmpPort"
                                            defaultValue={settings.mailStmpPort}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        Debug (true / false)
                                        <AdaInputText
                                            name="mailDebug"
                                            defaultValue={settings.mailDebug}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        SMTP Socket Port
                                        <AdaInputText
                                            name="mailStmpSocketPort"
                                            defaultValue={settings.mailStmpSocketPort}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        SMTP Socket Class
                                        <AdaInputText
                                            name="mailStmpSocketClass"
                                            defaultValue={settings.mailStmpSocketClass}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        SMTP Socket Fall Back
                                        <AdaInputText
                                            name="mailStmpSocketFallBack"
                                            defaultValue={settings.mailStmpSocketFallBack}
                                            errors={errors}
                                            innerRef={register}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <AdaButton
                                            type="submit">
                                            Valider
                                        </AdaButton>
                                        <AdaButton
                                            style={{marginLeft: 10}}
                                            onClick={() => history.push('./')}
                                        >
                                            Annuler
                                        </AdaButton>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        </div>}
        </div>
    );
};
export default Mail;
