import React, {useState, useEffect} from "react";
import {withRouter} from "react-router";
import {EMPLOYEE_NAME, getHostByAppName, IAM_NAME, MAIL_NAME} from "../../../service/host";

import {getAllPersonSelect} from "../../../service/employee.service";
import {get, getAllForSelect} from "../../../service/crud.service"
import EventLine from "./EventLine";

const Event = props => {
        const [isLoading, setIsLoading] = useState(true);
        const [employeeList, setEmployeeList] = React.useState([]);
        const [applicationList, setApplicationList] = React.useState([]);
        const [templateList, setTemplateList] = React.useState([]);
        const [eventSettingList, setEventSettingListe] = useState({});

        useEffect(() => {
            getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
        }, []);

        useEffect(() => {
            getAllForSelect(getHostByAppName(IAM_NAME), `application_modules`, setApplicationList)
        }, []);

        useEffect(() => {
            getAllForSelect(getHostByAppName(MAIL_NAME), `templates`, setTemplateList)
        }, []);

        useEffect(() => {
            get(getHostByAppName(MAIL_NAME), "event_settings", init, () => {
            })
        }, []);

        const init = (data) => {
            setEventSettingListe(data);
            console.log(eventSettingList);
            setIsLoading(false);
        }

        return (

            <div>
                {isLoading ? (<div></div>) :
                    (
                        <div className="row ma-2">
                            <div className="col-sm-12 ptb-15">
                                <div className="roe-card-style">
                                    <div className="roe-card-body">
                                        {eventSettingList && eventSettingList.map((data, i) => (
                                                <EventLine eventSetting={data} applicationList={applicationList}
                                                           employeeList={employeeList} templateList={templateList}
                                                           index={i} key={{i}}
                                                           id={i}/>

                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
;
export default Event;
