import React, {useState, Fragment, useEffect} from "react";
import {AdaButton, AdaInputText, AdaInputTextArea} from "@adaming/ada-react-component";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";

import * as service from "../../../service/crud.service";
import {useForm} from "react-hook-form";
import Col from "reactstrap/es/Col";
import {useHistory} from "react-router-dom";


export default function TemplateLine(props) {
    let history = useHistory();
    const context = "templates";
    const {register, handleSubmit, errors, setValue, control} = useForm();

    const [template, setTemplate] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [selectedEnableEvent, setSelectedEnableEvent] = React.useState(false);
    const [selectedEmployee, setSelectedEmployee] = React.useState(false);
    const [selectedCreator, setSelectedCreator] = React.useState(false);
    const [selectedEmployees, setSelectedEmployees] = React.useState();
    const [selectedApplicationModule, setSelectedApplicationModule] = React.useState();

    useEffect(() => {
            setTemplate(props.template);
            initData(props.template)
        },
        []
    );

    function initData(data) {

        setSelectedEnableEvent(data.enableEvent);
        setSelectedEmployee(data.employee);
        setSelectedCreator(data.creator);
    }


    const onSubmit = data => {
        console.log(data);
        template.name = data.name;
        template.subject = data.subject;
        template.content = data.content;
        if (template.id !== undefined) {
            service.put(`${process.env.REACT_APP_API_URL}/mail-service`, context, () => history.push("/" + context), () => {
            }, template)
        } else {
            service.post(`${process.env.REACT_APP_API_URL}/mail-service`, context, () => history.push("/" + context), () => {
            }, template)
        }
    };


    return (
        <Fragment key={props.index}>
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
                <div>
                    <div className="row ma-2">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body">
                                    <form onSubmit={handleSubmit(onSubmit)} id={props.index}>

                                        <Row>
                                            <Col >
                                                <FormGroup>
                                                    Nom
                                                    <AdaInputText
                                                        name="name"
                                                        defaultValue={template.name}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    Sujet
                                                    <AdaInputText
                                                        name="subject"
                                                        defaultValue={template.subject}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    Contenu
                                                    <AdaInputTextArea
                                                        name="content"
                                                        defaultValue={template.content}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <AdaButton
                                                        type="submit">
                                                        Valider
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </Fragment>
    );
}
;
